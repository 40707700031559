<script setup lang="ts">
const { t } = useI18n({ useScope: "global" });
const props = defineProps({
  config: Object,
});

import { createClient } from "@supabase/supabase-js";
const isOpen = ref(false);

const formType = ref("login");
const links = reactive([
  {
    label: computed(() => t("login")),
    icon: "i-ph-user-circle-fill",
    click: () => {
      formType.value = "login";
      isOpen.value = true;
    },
  },
  {
    label: computed(() => t("watchlist")),
    icon: "i-ph-star-fill",
    to: "/watchlist",
  },
]);

const user = useSupabaseUser();
if (user.value) {
  links[0] = reactive({
    label: computed(() => t("logout")),
    icon: "i-ph-user-circle-fill",
    click: async () => {
      await useSupabaseClient().auth.signOut();
      reloadNuxtApp();
    },
  });
}
</script>

<template>
  <div>
    <UAsideLinks label="Open" :links="links" :ui="config" />

    <UModal v-model="isOpen">
      <UCard class="w-full">
        <FormAuth v-if="formType === 'login'" @forgot-password="formType = 'forgot-password'"
          @sign-up="formType = 'sign-up'"></FormAuth>
        <FormSignUp v-if="formType === 'sign-up'" @login="formType = 'login'"></FormSignUp>
        <FormResetPassword v-if="formType === 'forgot-password'" @login="formType = 'login'"></FormResetPassword>
      </UCard>
    </UModal>
  </div>
</template>
