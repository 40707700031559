<script setup>
const openNav = ref(false);
const links = [
  {
    label: "Docs",
    icon: "i-heroicons-book-open",
    to: "/getting-started",
  },
  {
    label: "Pro",
    icon: "i-heroicons-square-3-stack-3d",
    to: "/pro",
  },
  {
    label: "Pricing",
    to: "/pro/pricing",
    icon: "i-heroicons-ticket",
  },
  {
    label: "Templates",
    icon: "i-heroicons-computer-desktop",
    to: "/pro/templates",
  },
  {
    label: "Releases",
    icon: "i-heroicons-rocket-launch",
    to: "/releases",
  },
];
</script>

<template>
  <SideNavigation :opened="openNav" />
  <div class="w-full h-screen overflow-x-hidden">
    <ClientOnly>
      <Navigation></Navigation>
    </ClientOnly>
    <slot />
  </div>
</template>
