<script setup lang="ts">
import { useEventBus } from "@vueuse/core";
const { toggleContentSearch } = useUIState();
const bus = useEventBus<boolean>("navOpened");
const localeStore = useLocaleStore();
const router = useRouter();
const locales = [
  {
    label: "EN",
    value: "en",
    icon: "i-flag-us-4x3",
  },
  {
    label: "RU",
    value: "ru",
    icon: "i-flag-ru-4x3",
  },
  {
    label: "ZH",
    value: "zh",
    icon: "i-flag-cn-4x3",
  },
];

const selectedLocale = ref(
  locales.find((locale) => locale.label === localeStore.locale.label) || locales[0]
);

useHead({
  htmlAttrs: {
    lang: selectedLocale.value.value,
  },
});

// Watch for changes in selectedLocale and update the store
watch(selectedLocale, (newLocale) => {
  localeStore.setLocale(newLocale);
  useHead({
    htmlAttrs: {
      lang: newLocale.value,
    },
  });
});

const isOpen = ref(false);

const users = [
  {
    id: "benjamincanac",
    label: "benjamincanac",
    href: "https://github.com/benjamincanac",
    target: "_blank",
    avatar: {
      src: "https://ipx.nuxt.com/s_16x16/gh_avatar/benjamincanac",
      srcset: "https://ipx.nuxt.com/s_32x32/gh_avatar/benjamincanac 2x",
      loading: "lazy",
    },
  },
  {
    id: "Atinux",
    label: "Atinux",
    href: "https://github.com/Atinux",
    target: "_blank",
    avatar: {
      src: "https://ipx.nuxt.com/s_16x16/gh_avatar/Atinux",
      srcset: "https://ipx.nuxt.com/s_32x32/gh_avatar/Atinux 2x",
      loading: "lazy",
    },
  },
  {
    id: "smarroufin",
    label: "smarroufin",
    href: "https://github.com/smarroufin",
    target: "_blank",
    avatar: {
      src: "https://ipx.nuxt.com/s_16x16/gh_avatar/smarroufin",
      srcset: "https://ipx.nuxt.com/s_32x32/gh_avatar/smarroufin 2x",
      loading: "lazy",
    },
  },
];

const commandPaletteRef = ref();

const groups = computed(() => [
  {
    key: "users",
    commands: users,
  },
]);

const ui = {
  wrapper: "flex flex-col flex-1 min-h-0 bg-gray-50 dark:bg-gray-800",
  input: {
    wrapper: "relative flex items-center mx-3 py-3",
    base:
      "w-full rounded border-2 border-primary-500 placeholder-gray-400 dark:placeholder-gray-500 focus:border-primary-500 focus:outline-none focus:ring-0 bg-white dark:bg-gray-900",
    padding: "px-4",
    height: "h-14",
    size: "text-lg",
    icon: {
      base: "pointer-events-none absolute left-3 text-primary-500 dark:text-primary-400",
      size: "h-6 w-6",
    },
  },
  group: {
    wrapper: "p-3 relative",
    label:
      "-mx-3 px-3 -mt-4 mb-2 py-1 text-sm font-semibold text-primary-500 dark:text-primary-400 font-semibold sticky top-0 bg-gray-50 dark:bg-gray-800 z-10",
    container: "space-y-1",
    command: {
      base:
        "flex justify-between select-none items-center rounded px-2 py-4 gap-2 relative font-medium text-sm group shadow",
      active: "bg-primary-500 dark:bg-primary-400 text-white",
      inactive: "bg-white dark:bg-gray-900",
      label: "flex flex-col min-w-0",
      suffix: "text-xs",
      icon: {
        base: "flex-shrink-0 w-6 h-6",
        active: "text-white",
        inactive: "text-gray-400 dark:text-gray-500",
      },
    },
  },
  emptyState: {
    wrapper: "flex flex-col items-center justify-center flex-1 py-9",
    label: "text-sm text-center text-gray-500 dark:text-gray-400",
    queryLabel: "text-lg text-center text-gray-900 dark:text-white",
    icon: "w-12 h-12 mx-auto text-gray-400 dark:text-gray-500 mb-4",
  },
};

function onSelect(option: any) {
  if (option.click) {
    option.click();
  } else if (option.to) {
    router.push(option.to);
  } else if (option.href) {
    window.open(option.href, "_blank");
  }
}

const selected = ref([]);
</script>

<template>
  <div class="w-full sticky top-0 left-0 right-0 z-[49] shadow-2xl">
    <div
      class="grid grid-cols-3 gap-8 w-full h-[75.6px] py-4 bg-gray-900 border-b border-gray-800"
    >
      <div class="col-span-1 lg:col-span-1 flex items-center justify-center">
        <nuxt-link to="/" class="flex mx-auto ml-4 lg:hidden">
          <NuxtImg
            format="webp"
            class="object-contain h-14"
            src="/logo-text.png"
            alt="Logo Icon"
          />
        </nuxt-link>
      </div>
      <div class="lg:flex hidden">
        <UModal v-model="isOpen">
          <UCommandPalette
            ref="commandPaletteRef"
            :ui="ui"
            :groups="groups"
            :autoselect="false"
            @update:model-value="onSelect"
          />
        </UModal>
      </div>
      <div
        class="col-span-2 lg:col-span-1 flex items-center justify-end gap-2 mr-2 md:pr-6"
      >
        <USelectMenu v-model="selectedLocale" :options="locales">
          <template #leading>
            <UIcon
              v-if="selectedLocale.icon"
              :name="(selectedLocale.icon as string)"
              class="w-5 h-5"
            />
          </template>
        </USelectMenu>
        <UButton
          aria-label="Search"
          icon="i-ph-magnifying-glass"
          size="xl"
          color="white"
          square
          variant="solid"
          @click="isOpen = true"
        />
        <UButton
          aria-label="Menu"
          class="lg:hidden"
          icon="i-ph-list-bold"
          size="xl"
          color="white"
          square
          variant="solid"
          @click="bus.emit(true)"
        />
      </div>
    </div>
  </div>
  <!-- <div class="grid gap-y-4 grid-cols-1 xl:grid-cols-2 my-4 w-full">
    <NuxtImg
      format="webm"
      class="w-full xl:pl-8 xl:pr-4 max-h-32"
      src="add.gif"
    ></NuxtImg>
    <NuxtImg
      format="webm"
      class="w-full xl:pr-8 xl:pl-4 max-h-32"
      src="add.gif"
    ></NuxtImg>
  </div> -->
</template>
